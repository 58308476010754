<template>
  <b-container fluid>
    <b-tabs>
      <b-tab>
        <template #title>
          <span>Estabelecimentos Comerciais</span>
        </template>
        <merchants :summary-endorsement="summaryEndorsement" />

      </b-tab>

      <b-tab>
        <template #title>
          <span>Contratos</span>
        </template>

        <endorsement :summary="summaryEndorsement" />
      </b-tab>

    </b-tabs>
  </b-container>
</template>

<script>
import {
    BContainer, BTab, BTabs,
} from 'bootstrap-vue';

import Merchants from './CreditPlatform/Merchants/Merchants.vue';
import Endorsement from './Endorsement/Endorsement.vue';

import EndorsementService from '@/services/osiris/EndorsementService';

export default {
    components: {
        BContainer, BTab, BTabs, Merchants, Endorsement,
    },

    data() {
        return {
            summaryEndorsement: {},
        };
    },

    async mounted() {
        const { data } = await EndorsementService.getSummary();

        this.summaryEndorsement = data;
    },
};
</script>

  <style>

  </style>
