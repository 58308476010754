<template>
  <b-container
    v-if="!isFetching"
    fluid
  >
    <b-card>
      <heading class="text-center">
        Estabelecimentos Comerciais
      </heading>

      <Summary
        class="mt-3"
        :items="fetchData.summary"
      />
    </b-card>

    <b-card>
      <b-row>
        <b-col
          class="mb-1"
        >
          <b-button
            class="d-block ml-auto"
            variant="primary"
            @click="$router.push('/plataforma-credito/estabelecimento/criar')"
          >
            Criar Estabelecimento Comercial
          </b-button>
        </b-col>

        <b-col md="12">
          <b-form-input
            v-model="filter"
            placeholder="Filtro"
          />
        </b-col>

      </b-row>

      <b-table
        :fields="fields"
        :items="items"
        :current-page="currentPage"
        :per-page="10"
        class="text-center mt-1"
        striped
        responsive="xl"
        hover
        :filter="filter.replace(/[^\w\s]/g, '')"
        @filtered="onFiltered"
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | date }}
        </template>

        <template #cell(tax_id)="data">
          {{ data.item.tax_id | tax_id }}
        </template>

        <template #cell(is_active)="data">
          <b-badge
            pill
            :variant="data.item.is_active ? 'success' : 'secondary'"
          >
            {{ data.item.is_active ? "ATIVO" : "INATIVO" }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template v-slot:button-content>
              <feather-icon
                icon="MoreHorizontalIcon"
                size="16"
                class="text-body align-middle mr-25"
              />
            </template>

            <b-dropdown-item @click="$router.push(`/plataforma-credito/estabelecimento/detalhes/${data.item.id}`)">
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>Detalhes</span>
            </b-dropdown-item>

            <b-dropdown-item @click="$router.push(`/plataforma-credito/estabelecimento/editar/${data.item.id}`)">
              <feather-icon
                icon="Edit2Icon"
                class="mr-50"
              />
              <span>Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="revokeUser(data.item.id)">
              <feather-icon
                icon="XCircleIcon"
                class="mr-50"
              />
              <span>Inativar</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="10"
        align="center"
        size="sm"
        class="mt-2"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BBadge, BContainer, BCard, BTable, BPagination, BDropdown, BDropdownItem, BFormInput, BButton, BRow, BCol,
} from 'bootstrap-vue';
import Heading from '@/views/components/Heading.vue';
import Summary from './Summary.vue';

import MerchantService from '@/services/osiris/MerchantService';

import formatDate from '@/filters/date';

export default {
    components: {
        BRow,
        BCol,
        BBadge,
        BContainer,
        BCard,
        Heading,
        Summary,
        BTable,
        BPagination,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BButton,
    },

    props: {
        summaryEndorsement: {
            required: true,
            type: Object,
        },
    },

    data() {
        return {
            currentPage: 1,
            filter: '',
            isFetching: true,
            fetchData: {
                summary: {},
                data: [],
            },
            totalItems: 0,
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'tax_id', label: 'CNPJ' },
                { key: 'legal_tax_name', label: 'Razão Social' },
                { key: 'created_at', label: 'Data da Criação', sortable:true},
                { key: 'is_active', label: 'Status',sortable:true},
                { key: 'actions', label: 'Ações'},
            ],
        };
    },

    computed: {
        items() {
            return this.fetchData.data.map(item => ({
                id: item.id,
                tax_id: item.tax_id,
                legal_tax_name: item.legal_tax_name,
                created_at: item.created_at,
                is_active: item.is_active,
            }));
        },
    },

    mounted() {
        this.getAllMerchants();
    },

    methods: {
        onFiltered(value) {
            this.totalItems = value.length;
        },
        async getAllMerchants() {
            this.isLoading(true);
            this.isFetching = true;
            const { data } = await MerchantService.index();

            data.summary.active_endorsements = this.summaryEndorsement.due_contracts;
            this.fetchData = data;

            this.totalItems = this.fetchData.data.length;

            this.isFetching = false;
            this.isLoading(false);
        },

        async revokeUser(id) {
            const { status, data } = await MerchantService.disable(id);
            if (status === 200) {
                await this.getAllMerchants();
                this.modalSuccess('Estabelecimento desativado com sucesso');
                return;
            }

            if (status === 406) {
                const { opt_ins, endorsements } = data;
                this.modalError(`<p> Não foi possível inativar o cliente pois ele tem opt-ins e averbações ativas </p> <p> Opt-ins: ${opt_ins.total_active} </p> <p> Data do último vencimento: ${formatDate(opt_ins.last_date)} </p> <br> <p>Averbações: ${endorsements.total_active} </p> <p> Data do último vencimento: ${endorsements.last_date ? formatDate(endorsements.last_date) : '------'} </p>`, true);
                return;
            }

            this.modalError('Não foi possível inativar o estabelecimento');
        },
    },
};
</script>
